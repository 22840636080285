<div>
    <img src="./assets/images/Home/cad_draft_service.jpg" width="100%" height="550px">
  </div>
<!-- about section -->

<section class="telecom_service_section layout_padding">
  <div class="container">
    <div class="heading_container">
      <h2><span>Service</span> Overview</h2>
    </div>
    <div class="row">
      <div class="col-md-6 px-0">
        <div class="img_container">
          <div class="img-box">
            <img src="./assets/images/Home/cad_draft.jpg" alt="" width="500px" height="400px" style="margin-left: -8%;" />
          </div>
        </div>
      </div>
      <div class="col-md-6 px-0">
        <div class="detail-box-about">
          Computer Aided Design services is use of computers to aid in the creation, modification and analysis of design. Our company CAD professional can able to convert any original CAD documents to a perfectly accurate multi-layer CAD drawings. And they can also transform your hand sketch drawings into accurate and computer generated to 2D, 3D drawings.
        </div>
      </div>
    </div>
  </div>
</section>

<!-- end about section -->

<section class="service_section_about layout_padding-top">
  <div class="container">
    <div class="heading_container">
      <div class="about_container">
        What we offer.
        <div class="offer_div" style="color: #ef5a24; margin-left: 0;">CAD Drawings from Sketches, PDF & image files</div>
        <div class="offer_div" style="margin-left: 0">Modification of CAD drawings with redline markups</div>
        <div class="offer_div" style="color: #29abe2; margin-left: 0">Architectural & Building plans</div>
        <div class="offer_div" style="color: #ed1e79; margin-left: 0">2D to 3D conversion</div>
        <div class="offer_div" style="color: #ef5a24; margin-left: 0;">Mechanical Drafting’s</div>
        <div class="offer_div" style="margin-left: 0">Structural Drawing</div>
        <div class="offer_div" style="color: #29abe2; margin-left: 0">Sheet Metal Design Drafting</div>
        <div class="offer_div" style="color: #ed1e79; margin-left: 0">Elevations, sections for site plans & floor plans</div>
      </div>        
    </div>
  </div>
</section>
