<div class="header_image">
    <div class="header_section">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg custom_nav-container">
                <a class="navbar-brand" routerLink="">
                    <img src="./assets/images/Home/logo.png" alt="" />
                </a>
                <div class="header_text"><a routerLink="">DBDA Design Partner</a></div>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" [routerLinkActive]="['active']">
                            <a class="nav-link" routerLink="home">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']">
                            <a class="nav-link" routerLink="about"> About</a>
                        </li>
                        <li class="nav-item dropdown"[routerLinkActive]="['active']">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbardrop"
                                data-toggle="dropdown"> Services </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" routerLink="telecom_service">Telecom Design Service</a>
                                <a class="dropdown-item" routerLink="designDraft">Design Drafting Service</a>
                            </div>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']">
                            <a class="nav-link" routerLink="contact">Contact us</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>