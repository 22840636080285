import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  "contactForm":FormGroup;
  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
    this.contactForm=this.fb.group({
      name:["",Validators.required],
      email:["",[Validators.required,Validators.email]],
      mobile:[null,[Validators.required]],
      message:[""]
    });
  }
  Submitcontact(){
    if(this.contactForm.valid){
      console.log(this.contactForm);
    }else{
      alert("Please Give the Correct Input");   
    }
  }
}
