<!-- end header section -->
<div>
  <img src="./assets/images/Home/About.jpg" width="100%" height="550px">
</div>

<!-- about section -->

<section class="about_section layout_padding">
  <div class="container">
    <div class="heading_container">
      <h2><span>About</span> DBDA</h2>
    </div>
    <div class="row">
      <div class="col-md-6 px-0">
        <div class="img_container">
          <div class="img-box">
            <img src="./assets/images/Home/About_Page.jpg" alt="" width="500px" height="400px"
              style="margin-left: -8%; margin-top: -8%;" />
          </div>
        </div>
      </div>
      <div class="col-md-6 px-0">
        <div class="detail-box-about">
          DBDA is a small young start-up team of highly qualified professionals with extensive international experience
          in the Telecom industry. We have completed Wireless telecom Drafting projects Such as General planning (GA),
          Detailed design (DD) and As-built (AB) drawing. Our ultimate aim is to create true value to customers with
          high quality project output and maintaining the accurate time for delivering the project. Thereby, becoming
          the most respected and effective telecommunication group. We are mostly focused on getting new opportunities
          and to satisfy the customer without any issues.
          Our work is to assist customers for their huge projects regularly, with our quality design inputs that aid
          project managers to plan and monitor their construction. Our experience is made to empower wireless network
          telecom infrastructure projects with tower mapping services towards quick and cost-effective completion.

        </div>
      </div>
    </div>
  </div>
</section>

<!-- end about section -->
<section class="service_section_about layout_padding-top">
  <div class="container">
    <div class="heading_container">
      <div class="about_container">
        The reasons for choose us are follows,
        <div class="offer_div" style="color: #ef5a24; margin-left: 0;"> Perfect Planning for delivery</div>
        <div class="offer_div" style="margin-left: 0"> Maintaining accurate standard</div>
        <div class="offer_div" style="color: #29abe2; margin-left: 0"> High quality without errors</div>
        <div class="offer_div" style="color: #ed1e79; margin-left: 0"> Without delay for delivery</div>
      </div>
    </div>
  </div>
</section>