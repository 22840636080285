import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { DesigndraftComponent } from './designdraft/designdraft.component';
import { HomeComponent } from './home/home.component';
import { TelecomserviceComponent } from './telecomservice/telecomservice.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  {path:"telecom_service",component:TelecomserviceComponent},
  {path:"designDraft",component:DesigndraftComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
