<section class="slider_section ">
  <div id="demo" class="carousel slide" data-ride="carousel">

    <!-- Indicators -->
    <ul class="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" class="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
      <li data-target="#demo" data-slide-to="3"></li>
    </ul>

    <!-- The slideshow -->
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="./assets/images/Slide/Telecom_Slide.jpg" alt="Los Angeles" width="100%" height="660">
        <div class="text-block">
          <h4>Telecom Design Services</h4>
        </div>
      </div>
      <div class="carousel-item">
        <img src="./assets/images/Slide/cad_draft_slide.jpg" alt="Chicago" width="100%" height="660">
        <div class="text-block">
          <h4>Design Drafting Services</h4>
        </div>
      </div>
      <div class="carousel-item">
        <img src="./assets/images/Slide/Quality_Slide.jpg" alt="New York" width="100%" height="660">
        <div class="text-block">
          <h4>Quality is our Brain</h4>
        </div>
      </div>
      <div class="carousel-item">
        <img src="./assets/images/Slide/Delivery_slide.png" alt="New York" width="100%" height="660">
        <div class="text-block">
          <h4>Delivery is our Breathe</h4>
        </div>
      </div>
    </div>

    <!-- Left and right controls -->
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</section>

<div>
  <section class="about_section layout_padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <div class="detail-box">
            <p>
              DBDA Design Partner is mainly focused to create true value to customers with high quality project output
              and maintaining the accurate time for delivering the project.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- end about section -->

  <!-- service section -->

  <section class="service_section layout_padding-top">
    <div class="container">
      <div class="heading_container">
        <div class="offer_container">
          We are offering
          <div class="offer_div"> Telecom Design Services & Design Drafting Services</div>
        </div>
      </div>
      <div class="service_container">
        <div class="box b1">
          <div class="img-box">
            <a routerLink="/telecom_service">
              <img src="./assets/images/Home/Telecom_Service.jpg" alt="" width="500px" height="400px" />
              <div class="text-block">
                <h4>Telecom Design Services</h4>
              </div>
            </a>
          </div>
        </div>
        <div class="box b2">
          <div class="img-box">
            <a routerLink="/designDraft">
              <img src="./assets/images/Home/cad_draft.jpg" alt="" width="500px" height="400px" />
              <div class="text-block">
                <h4>Design Drafting</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="qualtity_section layout_padding">
    <div class="container">
      <div class="heading_container">
        <h2><span>Quality</span></h2>
      </div>
      <div class="row">
        <div class="col-md-6 px-0">
          <div class="img_container">
            <div class="img-box">
              <img src="./assets/images/Home/Quality.jpg" alt="" width="550px" height="350px" />
            </div>
          </div>
        </div>
        <div class="col-md-6 px-0">
          <div class="detail-box" style="width: 620px;">
            <h3>
              Quality is never an accident. It is always the result of intelligent effort.<br>
              <sub>- John Ruskin</sub>
            </h3>
            <p>
              Quality means constantly pursing excellence and making sure that what your organization does is fit for
              purpose, and not only stay that way but keeps improving. Delivering an acceptable level of quality to
              customer with perfect accuracy means that the organization is in good state of improvements and success.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 5%;">
      <div class="heading_container">
        <h2><span>Delivery</span></h2>
      </div>
      <div class="row">
        <div class="col-md-6 px-0">
          <div class="detail-box" style="width: 550px;">
            <h3>
              Victims make excuses. Leader deliver results.<br>
              <sub>- Robin Sharma</sub>
            </h3>
            <p>
              Successful project delivery requires the implementation of management systems that will control changes in
              the key factor of scope, schedule, budget, resources and risk to optimize quality and therefore the
              investments.
            </p>
          </div>
        </div>
        <div class="col-md-6 px-0">
          <div class="img_container">
            <div class="img-box">
              <img src="./assets/images/Home/Delivery.png" alt="" width="600px" height="350px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>