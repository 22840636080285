<div>
  <img src="./assets/images/Home/contact.jpg" width="100%" height="550px">
</div>

<section class="contact_section layout_padding">
  <div class="container">
    <div class="heading_container">
      <h2>
        Get In touch
      </h2>
    </div>
    <div class="">
      <div class="row">
        <div class="col-md-6 ">
            <div class="contact_form-container">
              <form [formGroup]="contactForm" (ngSubmit)="Submitcontact()">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <input type="text" class="form-control" formControlName="name" id="name" placeholder="Full Name">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <input type="text" class="form-control" formControlName="email" id="email" placeholder="Email">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <input type="number" class="form-control" formControlName="mobile" id="mobile"
                      placeholder="Phone Number">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <textarea class="form-control" rows="6"  formControlName="message" id="message"
                      placeholder="Message"></textarea>
                  </div>
                </div>
                <div class=" ">
                  <button type="submit">
                    Send
                  </button>
                </div>
              </form>              
            </div>
           
        </div>
        <div class="col-md-6">
          <div class="img-box">
            <img src="./assets/images/map.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>