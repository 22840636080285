<!-- info section -->
<section class="info_section ">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_logo">
            <a class="navbar-brand"routerLink="home">
              <img src="./assets/images/Home/logo.png" alt="" />
            </a>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <div class="info_form ">
            <h5>
              Contact
            </h5>
            <div>
              <p><a href="mailto:dbda@dbdadesign.com">dbda@dbdadesign.com</a></p>
              <h4>99, Lakshmanasami salai, 
                K.K.Nagar, Chennai - 600078,
                India.</h4>
            </div>       
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid footer_section">
    <h4>
      Copyright DBDA Design partner &copy; 2021. All Rights Reserved.
    </h4>
</div>