
    <!-- end header section -->
    <div>
        <img src="./assets/images/Home/Telecom.jpg" width="100%" height="550px">
      </div>
  
    <!-- about section -->
  
    <section class="telecom_service_section layout_padding">
      <div class="container">
        <div class="heading_container">
          <h2><span>Service</span> Overview</h2>
        </div>
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="img_container">
              <div class="img-box">
                <img src="./assets/images/Home/Telecom_Service.jpg" alt="" width="500px" height="400px" style="margin-left: -8%;" />
              </div>
            </div>
          </div>
          <div class="col-md-6 px-0">
            <div class="detail-box-about">
              In telecommunication our company provides the best design and drawings with complete quality to customers. Our company build up the site with basic telecom components, as per the customers details. Then we move on to electrical, civil and structural drafting part to complete the construction. And we also cover the site upgradation. Then we move on to the final level of drafting with adapting the modification of before stage along with the photos.
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <!-- end about section -->
  
    <section class="service_section_about layout_padding-top">
      <div class="container">
        <div class="heading_container">
          <div class="about_container">
            What we offer.
            <div class="offer_div" style="color: #ef5a24; margin-left: 0;">Preliminary design/General Planning</div>
            <div class="offer_div" style="margin-left: 0">Construction/Detail design</div>
            <div class="offer_div" style="color: #29abe2; margin-left: 0">Asbuilt Capturing & Drawings</div>      
          </div>        
        </div>
      </div>
    </section>